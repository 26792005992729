import Loader from 'react-loader-spinner';

const FileLoader = () => {
  return (
    <div className='FileLoader'>
      <Loader type='Oval' color='#00BFFF' height={50} width={50} />
    </div>
  );
};

export default FileLoader;
