import Button from './Button';

import AWS from 'aws-sdk';
import { GetObjectRequest } from 'aws-sdk/clients/s3';
import { String } from 'aws-sdk/clients/acm';

const Download = (props: { disabled: boolean; fileName: String }) => {
  const { disabled, fileName } = props;

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
    httpOptions: {},
  });

  const handleDownload = () => {
    const s3 = new AWS.S3();

    const params: GetObjectRequest = {
      Bucket: 'route-opt-results',
      Key: `${fileName}`,
      ResponseContentType:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };
    function downloadBlob(blob: Blob, name = `${fileName}`) {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = name;
      document.body.appendChild(link);
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      document.body.removeChild(link);
    }

    s3.getObject(params as GetObjectRequest, (err, data) => {
      if (err) {
        console.log(err, err.stack);
      } else {
        let csvBlob = new Blob([data.Body as BlobPart], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        downloadBlob(csvBlob, `${fileName}`);
      }
    });
  };

  return (
    <div>
      <Button title='Download' disabled={disabled} onClick={handleDownload} />
    </div>
  );
};

export default Download;
