import axios, { AxiosRequestConfig } from 'axios';
import { uploadFile } from '../components/FileUpload';
import { polingTime, timeoutTime } from '../constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const uploadFiles = (
  file: uploadFile,
  params: { file: String },
  setIsComplete: (status: boolean) => void,
  setIsDownloadable: (status: boolean) => void
) => {
  const formData = new FormData();
  formData.append('file', file.file);
  formData.append(
    'Metadata',
    JSON.stringify({
      filename: file.id,
    })
  );
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  axios
    .post('/process_request', formData, config)
    .then(response => {
      if (response.status === 200 && response.data === 'Hello from Lambda!') {
        let startTime = new Date().getTime();

        let refreshInterval = setInterval(() => {
          getStatus(params)
            .then(response => {
              if (response.data === 'found') {
                setIsComplete(false);
                setIsDownloadable(true);
                clearInterval(refreshInterval);
                toast.success('File uploaded successfully.', {
                  position: toast.POSITION.BOTTOM_LEFT,
                });
              }
            })
            .catch(err => {
              console.log(err);
              clearInterval(refreshInterval);
              toast.error('Something went wrong.', {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            });
          if (new Date().getTime() - startTime > timeoutTime) {
            setIsDownloadable(false);
            setIsComplete(false);
            clearInterval(refreshInterval);
            toast.error('Something went wrong.', {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
        }, polingTime);
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const getStatus = (params: { file: String }) => {
  const config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    params: {file: params.file.split('.')[0]},
  };

  return axios.get('/check_status', config);
};
