import '../styles/button.css';

interface ButtonData {
  title: string;
  onClick: () => void;
  disabled?: boolean;
}

const Button = (props: ButtonData) => {
  const { title, onClick, disabled } = props;
  return (
    <div>
      <button className='button' disabled={disabled} onClick={onClick}>
        {title}
      </button>
    </div>
  );
};

export default Button;
