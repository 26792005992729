import FileLoader from './FileLoader';
import Button from './Button';
import '../styles/uploader.css';
import { useState } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { uploadFiles } from '../apis/uploadFiles';
import Download from './Download';
import { DropzoneArea } from 'material-ui-dropzone';

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
export interface uploadFile {
  file: File;
  id: string;
}

const FileUpload = () => {
  const [excelFile, setExcelFile] = useState({} as uploadFile);
  const [isComplete, setIsComplete] = useState(false);
  const [isDownloadable, setIsDownloadable] = useState(false);

  const handleOnChange = (files: File[]) => {
    if (files.length) {
      let fileNameArray = files[0].name.split('.');
      let extension = fileNameArray[fileNameArray.length - 1];
      let uploadedFile: uploadFile = {
        file: files[0],
        id: `${uuidv1()}.${extension}`,
      };
      setExcelFile(uploadedFile || {});
    }
  };

  const handleOnDelete = (file: File) => {
    setIsComplete(false);
  };

  // const handleDownload = (complete: boolean) => {

  // }

  const handleSubmit = () => {
    setIsComplete(true);
    setIsDownloadable(false);

    const apiParams = {
      file: (excelFile as uploadFile).id,
    };

    uploadFiles(
      excelFile as uploadFile,
      apiParams,
      setIsComplete as (status: boolean) => void,
      setIsDownloadable as (status: boolean) => void
    );
  };

  return (
    <div>
      <div
        className='uploader'
        style={{ width: '50%', margin: '5% auto 0 auto' }}
      >
        <DropzoneArea
          acceptedFiles={[
            '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
          ]}
          showPreviewsInDropzone={true}
          previewGridProps={{ container: { direction: 'column' } }}
          useChipsForPreview
          previewText='Selected files'
          onChange={(files: File[]) => handleOnChange(files)}
          onDelete={(file: File) => handleOnDelete(file)}
          dropzoneText={'Drag and drop an image here or click'}
          filesLimit={1}
          maxFileSize={500000}
          clearOnUnmount
        />
      </div>
      <Button
        title='Submit'
        disabled={!!!excelFile.id}
        onClick={() => handleSubmit()}
      />
      {isComplete ? <FileLoader /> : null}
      <Download fileName={excelFile.id} disabled={!isDownloadable} />
      <ToastContainer theme={'colored'} />
    </div>
  );
};

export default FileUpload;
